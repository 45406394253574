import { Button, Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
//import icon from react icons 
import { LuLayoutDashboard } from 'react-icons/lu'
import { FaRegUser } from 'react-icons/fa6'
import { MdDelete, MdOutlineEmail, MdOutlineGroups } from "react-icons/md";
import { RiMotorbikeFill } from "react-icons/ri";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { MdOutlineEventNote } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { SiApostrophe } from "react-icons/si";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { BsSpeedometer } from "react-icons/bs";
import { HiOutlineUsers } from "react-icons/hi";
import { FaUsers } from "react-icons/fa6";
import { MdOutlineColorLens } from "react-icons/md";
import { TbRulerMeasure } from "react-icons/tb";
import { MdOutlineShoppingCart } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { FaCartArrowDown, FaRegImages } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import { MdOutlineFeedback } from "react-icons/md";

//import css
import './sidebar.css'
//import images
import brand_logo from '../assets/brandlogo/logo.png';
import logo_white from "../assets/Homepage_image/logo-white.svg";

const navigation = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: <LuLayoutDashboard />,
  },
  {
    title: "Users",
    href: "users",
    icon: <FaRegUser />,
  },
  {
    title: "Events",
    href: "/events",
    icon: <MdOutlineEventNote />,
  },
  {
    title: "Groups",
    href: "/groups",
    icon: <FaUsers />,
  },
  {
    title: "Clubs",
    href: "/clubs",
    icon: <GiFullMotorcycleHelmet />,
  },
  {
    title: "Club Requests",
    href: "/clubRequests",
    icon: <FiSend />,
  },
  // {
  //   title: "Rides",
  //   href: "/rides",
  //   icon: <BsSpeedometer />,
  // },
  {
    title: "Club Delete Requests",
    href: "/club-delete-requests",
    icon: <MdDelete />,
  },
  {
    title: "Orders",
    href: "/orders",
    icon: <FaCartArrowDown />,
  },
  {
    title: "Brands",
    href: "/brands",
    icon: <RiMotorbikeFill />,
  },
  {
    title: "Vendors",
    href: "/vendors",
    icon: <HiOutlineUsers />,
  },
  {
    title: "Colors",
    href: "/colors",
    icon: <MdOutlineColorLens />,
  },
  {
    title: "Sizes",
    href: "/sizes",
    icon: <TbRulerMeasure />,
  },
  {
    title: "Categories",
    href: "/categories",
    icon: <BiCategory />,
  },
  {
    title: "Products",
    href: "/products",
    icon: <MdOutlineShoppingCart />,
  },
  {
    title: "Banner Images",
    href: "/bannerImages",
    icon: <FaRegImages />,
  },
  {
    title: "Reports",
    href: "/reports",
    icon: <FaRegFileAlt />,
  },
  {
    title: "Feedback",
    href: "/feedback",
    icon: <MdOutlineFeedback />,
  },
  {
    title: "Vendor Inquiry",
    href: "/vendor-inquiry",
    icon: <MdOutlineEmail />,
  },
  {
    title: "Setting",
    href: "/setting",
    icon: <IoSettingsOutline />,
  }
];

const Sidebar = () => {
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const location = useLocation();
  let filteredNavigation = navigation;

  if (localStorage.getItem("userType") === 'vendor') {
    filteredNavigation = navigation.filter(item => ['Dashboard', 'Products', 'Categories' , 'Orders','Colors'].includes(item.title));
  } else if (localStorage.getItem("userType") === 'clubs') {
    filteredNavigation = navigation.filter(item => ['Dashboard', 'Clubs', 'Events'].includes(item.title));
  } else if (localStorage.getItem("userType") === 'admin') {
    filteredNavigation = navigation.filter(item => item.title !== 'Orders');
  }

  return (
    <div className="p-3" style={{ height: "100%" }}>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <img src={logo_white} alt="brand logo" className="sidebar_brand_logo mt-lg-3" />
        </div>
        <span className="ms-auto d-lg-none">
          {/* <Button close size="sm" className="ms-auto d-lg-none" onClick={() => showMobilemenu()}></Button> */}
          <button type="button" class="btn-close btn-close-white" aria-label="Close" onClick={() => showMobilemenu()}></button>
        </span>
      </div>
      <div className="sidebarNav">
        <Nav vertical>
          {filteredNavigation.map((res, index) => (
            <NavItem key={index} className="sidenav-bg">
              <NavLink
                to={res.href}
                className={({ isActive }) =>
                  isActive ? "text-primary nav-link py-2" : "nav-link text-secondary py-2"
                }
              >
                <span className="sidebar_icons">{res?.icon}</span>
                <span className="ms-3 d-inline-block">{res.title}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
