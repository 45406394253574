import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  viewOrderDetail,
  generateAWB,
  generateLabel,
  generatePickup,
  cancelOrder,
} from "../../../../helpers/Redux/api";
import { toast } from "react-toastify";

const OrderInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetail, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;
  const [loading, setLoading] = useState(true);
  const [awbLoading, setAwbLoading] = useState(false); // For AWB generation loading state
  const [labelLoading, setLabelLoading] = useState(false); // For Label generation loading state
  const [pickupLoading, setPickupLoading] = useState(false); // For Pickup creation loading state
  const [cancelLoading, setCancelLoading] = useState(false); // For Cancel order loading state

  const order_id = location.pathname?.split("/")[3];

  const handleGetData = () => {
    setLoading(true);
    try {
      dispatch(viewOrderDetail(order_id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.orderDetails[0]?.data);
        } else {
          setLoading(false);
          setInitialValue(orderDetail);
        }
      });
    } catch (error) {
      console.log(error, "from view Order details");
    }
  };

  const handleGenerateAWB = () => {
    setAwbLoading(true);
    dispatch(generateAWB({ shipment_id: orderDetail?.shipments?.id }))
      .then((res) => {
        setAwbLoading(false);
        if (res?.payload?.status === true) {
          toast.success("AWB generated successfully!");
          handleGetData();
        } else {
          toast.error("Failed to generate AWB.");
        }
      })
      .catch((error) => {
        setAwbLoading(false);
        console.error("Error generating AWB:", error);
        toast.error("Error generating AWB.");
      });
  };

  const handleGenerateLabel = () => {
    setLabelLoading(true);
    dispatch(generateLabel({ shipment_id: orderDetail?.shipments?.id }))
      .then((res) => {
        setLabelLoading(false);
        if (res?.payload?.status === true) {
          toast.success("Label generated successfully!");
          if (res?.payload?.label_url) {
            window.location.href = res.payload.label_url;
          }
          console.log(res.payload);

          handleGetData();
        } else {
          toast.error("Failed to generate Label.");
        }
      })
      .catch((error) => {
        setLabelLoading(false);
        console.error("Error generating Label:", error);
        toast.error("Error generating Label.");
      });
  };

  const handleGeneratePickup = () => {
    setPickupLoading(true);
    dispatch(generatePickup({ shipment_id: [orderDetail?.shipments?.id] }))
      .then((res) => {
        setPickupLoading(false);
        if (res?.payload?.status === true) {
          toast.success("Pickup generated successfully!");
          handleGetData();
        } else {
          toast.error("Failed to generate Pickup.");
        }
      })
      .catch((error) => {
        setPickupLoading(false);
        console.error("Error generating Pickup:", error);
        toast.error("Error generating Pickup.");
      });
  };

  const handleCancelOrder = () => {
    setCancelLoading(true);
    dispatch(cancelOrder({ ids: [orderDetail?.id] }))
      .then((res) => {
        setCancelLoading(false);
        if (res?.payload?.status === true) {
          toast.success("Order canceled successfully!");
          navigate("/orders", { state: { previousPageCount } });
        } else {
          toast.error("Failed to cancel order.");
        }
      })
      .catch((error) => {
        setCancelLoading(false);
        console.error("Error canceling order:", error);
        toast.error("Error canceling order.");
      });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}     - ` + getFormattedTime(dateString);
  };

  const getFormattedTime = (isoString) => {
    const date = new Date(isoString);
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return "    " + date.toLocaleTimeString("en-US", timeOptions);
  };

  return (
    <section className="add_section">
      {loading ? (
        <></>
      ) : (
        <div className="view_information_cont_inner">
          {orderDetail?.channel_order_id && (
            <div className="information_details_cont">
              <label className="label">Order ID</label>:
              <p className="details">{orderDetail?.id}</p>
            </div>
          )}
          {orderDetail?.customer_name && (
            <div className="information_details_cont">
              <label className="label">Customer Name</label>:
              <p className="details ">{orderDetail?.customer_name}</p>
            </div>
          )}
          {orderDetail?.customer_email && (
            <div className="information_details_cont">
              <label className="label">Customer Email</label>:
              <p className="details ">{orderDetail?.customer_email}</p>
            </div>
          )}
          {orderDetail?.billing_mobile_country_code && (
            <div className="information_details_cont">
              <label className="label">Customer Phone</label>:
              <p className="details ">
                {orderDetail?.billing_mobile_country_code}{" "}
                {orderDetail?.customer_phone}
              </p>
            </div>
          )}
          {orderDetail?.order_date && (
            <div className="information_details_cont">
              <label className="label">Order Date </label>:
              <p className="details ">{orderDetail?.order_date || "NA"}</p>
            </div>
          )}
          {orderDetail?.total && (
            <div className="information_details_cont">
              <label className="label">Total Amount</label>:
              <p className="details ">{orderDetail?.total}</p>
            </div>
          )}
          {orderDetail?.status && (
            <div className="information_details_cont">
              <label className="label">Status</label>:
              <p className="details ">{orderDetail?.status}</p>
            </div>
          )}
          {orderDetail?.shipments?.shipped_date && (
            <div className="information_details_cont">
              <label className="label">Shipped Date</label>:
              <p className="details ">
                {orderDetail?.shipments?.shipped_date
                  ? getFormattedDate(orderDetail?.shipments?.shipped_date)
                  : "NA"}
              </p>
            </div>
          )}
          {orderDetail?.shipments?.delivered_date && (
            <div className="information_details_cont">
              <label className="label">Delivered Date</label>:
              <p className="details ">
                {orderDetail?.shipments?.delivered_date
                  ? getFormattedDate(orderDetail?.shipments?.delivered_date)
                  : "NA"}
              </p>
            </div>
          )}
          {orderDetail?.customer_address && (
            <div className="information_details_cont">
              <label className="label">Address</label>:
              <p className="details ">{orderDetail?.customer_address}</p>
            </div>
          )}
          {orderDetail?.customer_city && (
            <div className="information_details_cont">
              <label className="label">City</label>:
              <p className="details ">{orderDetail?.customer_city}</p>
            </div>
          )}
          {orderDetail?.customer_state && (
            <div className="information_details_cont">
              <label className="label">State</label>:
              <p className="details ">{orderDetail?.customer_state}</p>
            </div>
          )}
          {orderDetail?.customer_pincode && (
            <div className="information_details_cont">
              <label className="label">Pincode</label>:
              <p className="details ">{orderDetail?.customer_pincode}</p>
            </div>
          )}
          {orderDetail?.customer_country && (
            <div className="information_details_cont">
              <label className="label">Country</label>:
              <p className="details ">{orderDetail?.customer_country}</p>
            </div>
          )}

          <p
            className="btn btn-success mt-4"
            onClick={() =>
              navigate("/orders", { state: { previousPageCount } })
            }
          >
            Back
          </p>

          <p
            className={`btn btn-primary mt-4 mx-3 ${
              orderDetail?.awb_data?.awb || orderDetail?.status === "CANCELED"
                ? "d-none"
                : "d-inline-block"
            }`}
            onClick={handleGenerateAWB}
            disabled={awbLoading || orderDetail?.awb_data?.awb ? true : false}
          >
            {awbLoading ? "Generating AWB..." : "Generate AWB"}
          </p>

          <p
            className={`btn btn-primary mt-4 mx-3 ${
              !orderDetail?.awb_data?.awb || orderDetail?.status === "CANCELED"
                ? "d-none"
                : "d-inline-block"
            } ${labelLoading ? "disabled" : ""}`}
            onClick={handleGenerateLabel}
          >
            {labelLoading ? "Generating Label..." : "Generate Label"}
          </p>

          <p
            className={`btn btn-primary mt-4 mx-3 ${
              !orderDetail?.awb_data?.awb ||
              orderDetail?.status === "CANCELED" ||
              orderDetail?.shipments?.pickup_scheduled_date
                ? "d-none"
                : "d-inline-block"
            } ${pickupLoading ? "disabled" : ""}`}
            onClick={handleGeneratePickup}
          >
            {pickupLoading ? "Generating Pickup..." : "Generate Pickup"}
          </p>

          <p
          className={`btn btn-danger mt-4 mx-3 ${
            orderDetail?.status === "CANCELED" ? "d-none" : "d-inline-block"
          } ${cancelLoading ? "disabled" : ""}`}
          onClick={handleCancelOrder}
        >
          {cancelLoading ? "Canceling Order..." : "Cancel Order"}
        </p>
        </div>
      )}
    </section>
  );
};

export default OrderInfo;
